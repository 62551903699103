.dataset-index-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 56px);
    padding: 64px;
    gap: 60px;
    margin-top: 56px;
}

.dataset-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 56px;
    gap: 8px;
}

.dataset-topbar .title-container {
    font-family: Inter, monospace;
    font-size: 28px;
    font-weight: 400;
    width: 100%;
}

.dataset-list-container {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
}

.dataset-row {
    gap: 32px;
    justify-content: space-between !important;
    border-bottom: 1px solid #f2f2f2;
}

.dataset-row-disabled {
    pointer-events: none;
}

.dataset-row:hover {
    background-color: #f9f9f9;
}

.dataset-row-disabled:hover {
    background-color: transparent;
}

.dataset-icon {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.last-scoop-date {
    overflow: hidden;
    color: #635566;
    text-overflow: ellipsis;
    font-family: Inter, monospace;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.28px;
    width: fit-content;
}

.MuiLinearProgress-root {
    background-color: #fce7ee !important;
}

.MuiLinearProgress-bar {
    background-color: #e50b54 !important;
}
