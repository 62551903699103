.dialog-content-container {
    display: flex;
    flex-direction: column;
    padding: 24px !important;
    gap: 16px;
    color: #14092a !important;
}

.dialog-content-container .MuiFormControl-root {
    margin-top: 0 !important;
}

.dialog-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 !important;
    margin: 0 !important;
}

.dialog-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family:
        Spline Sans,
        sans-serif !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    padding: 0 !important;
}

.dialog-xButton {
    cursor: pointer;
}

.dialog-xButton-w-image {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
}
