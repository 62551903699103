.switchBase {
    width: 28px;
    height: 16px;
    padding: 0px;
    display: flex;
}
.switchThumb {
    box-shadow: 0 2px 4px 0 rgb(0 35 11 / 20%);
    width: 12px;
    height: 12px;
    border-radius: 6px;
}
