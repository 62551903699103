.prompt-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 12px;
}

.prompt-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.config-button {
    border-radius: 5px !important;
    font-family: Inter, sans-serif;
    font-size: 14px !important;
    text-transform: none !important;
    width: 100% !important;
    height: 38px;
    line-height: 115% !important;
    color: #2b1630 !important;
    background-color: #f2f2f2 !important;
}
