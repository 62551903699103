.scrollbar {
    position: fixed;
    background-color: rgba(99, 85, 102, 0.5);
    border-radius: 5px;
}

.vertical {
    width: 10px;
}

.horizontal {
    height: 10px;
}
