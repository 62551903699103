.account-screen-container {
    padding: 120px 64px 64px 64px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #fff;
}

.account-title-container {
    font-family: Inter, monospace;
    font-size: 28px;
    font-weight: 400;
    width: 100%;
}
