.dataset-options-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    gap: 10px;
}

.dataset-option-box {
    display: flex;
    padding: 32px 24px;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    text-align: center;
    border-radius: 5px;
    border: 1px solid #e6e4e6;
    font-size: 14px;
    cursor: pointer;
}

.dataset-option-box.selected {
    border: 1px solid #bf0a46;
    background: #fefafc;
}

.dataset-option-box.disabled {
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
}

.dataset-type-box {
    display: flex;
    padding: 32px 24px;
    gap: 16px;
    border-radius: 5px;
    border: 1px solid #e6e4e6;
    cursor: pointer;
}
.dataset-type-box.selected {
    border: 1px solid #bf0a46;
    background: #fefafc;
}

.sources-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns of equal width */
    grid-template-rows: auto auto auto auto auto; /* Five rows */
    gap: 12px; /* Optional: space between rows and columns */
    height: 400px;
    overflow-y: auto;
}
.source-box {
    border-radius: 5px;
    border: 1px solid #e6e4e6;
    display: flex;
    gap: 8px;
    padding: 11px;
    align-items: center;
    font-size: 14px;
    min-width: 220px;
    cursor: pointer;
}

.connections-container {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    height: 400px;
    overflow-y: auto;
    align-content: flex-start;
}
.connections-box {
    width: calc(50% - 6px);
    box-sizing: border-box;
    border-radius: 5px;
    border: 1px solid #e6e4e6;
    display: flex;
    gap: 8px;
    padding: 11px;
    align-items: center;
    font-size: 14px;
    min-width: 220px;
    cursor: pointer;
    max-height: 64px;
}

.selected-box {
    border: 1px solid #bf0a46;
    background: #fefafc;
}

.source-box:hover {
    border: 1px solid #bf0a46;
    background: #fefafc;
}

.connections-box:hover {
    border: 1px solid #bf0a46;
    background: #fefafc;
}

.remove-hover {
    cursor: auto;
}

.source-icon {
    width: 40px;
    padding: 8px;
    border-radius: 5px;
}

.disabled-element {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
    pointer-events: none !important;
    position: relative;
}
