.KPI-compare {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.KPI-compare-icon {
    padding: 2px;
    border-radius: 5px;
    height: fit-content;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 5px;
}
