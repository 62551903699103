.style-tab-wrapper {
    height: 100%;
    overflow: auto;
}

.switch-theme-button {
    min-height: unset !important;
    height: 38px !important;
    min-width: unset !important;
    width: 100% !important;
    font-size: 14px !important;
    margin-top: 8px !important;
}

.style-config-button {
    display: flex;
    flex-direction: column;
}

.style-config-button:hover {
    cursor: pointer;
    background-color: #f9f9f9;
}

.style-config-button-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 16px 8px;
}

.style-config-label {
    font-size: 12px !important;
    font-weight: 600 !important;
    font-family: Inter, sans-serif !important;
}

.style-divider {
    height: 1px;
    background-color: #e6e4e6;
    width: 100%;
}

.red-dot {
    height: 5px;
    width: 5px;
    border-radius: 50%;
    background-color: #e50b54;
    margin-left: 5px;
}

.tab-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 1px solid #e6e4e6;
    position: relative;
}

.tab-header-title {
    font-size: 14px !important;
    font-weight: 600 !important;
    font-family: Inter, sans-serif !important;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
}

.config-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.disabled {
    pointer-events: none;
    opacity: 0.6;
}

.disabled .switch-override {
    pointer-events: auto;
    opacity: 1;
}

.themes-list {
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 8px;
    height: 100%;
}

.themes-searchbar {
    display: flex;
    flex-direction: row;
    align-items: center;
}
