.date-picker-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.date-picker-input {
    height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
}

.date-picker-input:hover {
    cursor: pointer;
}

.date-picker-popup {
    padding: 12px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.options-and-calendar {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.range-options-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8px;
}

.range-option {
    width: 200px;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #f2f2f2;
}

.range-option-selected {
    color: #e50b54;
    background-color: #fce7ee;
    border: 1px solid transparent;
}

.range-option:hover {
    cursor: pointer;
}

.calendar-container {
    width: 300px;
    height: 322px;
    padding: 16px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
}

.date-picker-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 8px;
    gap: 8px;
    align-items: flex-end;
}

.date-picker-button {
    min-height: unset !important;
    height: 30px !important;
    min-width: unset !important;
    width: 80px !important;
}
