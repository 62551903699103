.numeric-range-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.numeric-range-input {
    height: 38px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px;
}

.numeric-range-value .MuiInputBase-root {
    height: 38px !important;
    font-family: Inter, sans-serif;
    font-size: 14px;
    margin-top: 10px !important;
}

.numeric-range-label {
    color: #14092a;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: -20px;
}

.numeric-range-input:hover {
    cursor: pointer;
}

.numeric-range-popup {
    padding: 12px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
}

.numeric-range-footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 8px;
    gap: 8px;
    align-items: flex-end;
}

.numeric-range-button {
    min-height: unset !important;
    height: 30px !important;
    min-width: unset !important;
    width: 80px !important;
}
