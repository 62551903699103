.toolbar-container {
    position: fixed;
    top: 140px;
    left: 20px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid #e4e1eb;
    background: #fff;
    padding: 20px 16px;
    gap: 19px;
    box-shadow: 0 0 7px 0 rgba(20, 9, 42, 0.15);
}

.toolbar-button-icon {
    height: 30px;
    width: 30px;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
}
.toolbar-button-icon:hover {
    background: #eee;
    border-radius: 4px;
}

.objects-menu-container {
    position: fixed;
    top: 455px;
    left: 90px;
    font-size: 14px;
    width: 236px;
    padding: 16px;
    border-radius: 8px;
    border: 1px solid #e4e1eb;
    background-color: white;
    box-shadow: 0 0 7px 0 rgba(20, 9, 42, 0.15);
}

.objects-icons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 10px;
    gap: 10px;
}

.selected-icon {
    background-color: #fce7ee;
    border-radius: 3px;
}

.selected-icon:hover {
    background-color: #fce7ee;
}
