.navbar {
    z-index: 1000;
    background-color: #201024 !important;
    height: 56px;
    min-height: 50px !important;
    justify-content: center;
}

.scoop-logo-container {
    display: flex;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.navbar-items-container {
    display: flex;
    align-items: center;
    height: 100%;
}

.navbar-item {
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 0 !important;
    gap: 4px;
    font-size: 14px;
    letter-spacing: -0.28px;
    color: #ffffff;
    cursor: pointer;
}

header {
    box-shadow: none !important;
}
