.sub-nav-actions {
    display: flex;
    align-items: center;
}
.sub-nav-canvas-name {
    padding-right: 8px;
    padding-left: 8px;
    font-size: 14px;
}

.sub-nav-canvas-name:hover {
    cursor: pointer;
}

.canvas-scrollable {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.canvas-scrollable::-webkit-scrollbar {
    display: none;
}

.prompt-drawer-content {
    padding: 24px;
    width: 400px;
    height: calc(100% - 115px);
    display: flex;
    flex-direction: column;
}

.prompt-drawer-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.prompts-controls {
    flex: 1;
}

.prompt-link-button {
    height: 38px;
    width: 38px;
    border-radius: 0 5px 0 5px !important;
}

.prompt-label-input {
    width: 100%;
}

.prompt-label-input .MuiInputBase-root {
    height: 38px !important;
    font-family: Inter, sans-serif;
    font-size: 14px;
}

.prompt-label-input .Mui-focused .MuiOutlinedInput-notchedOutline {
    border: 1.5px solid #e50b54 !important;
}

.prompt-label-label {
    color: #14092a;
    font-family: Inter, sans-serif;
    font-size: 12px;
    font-weight: 600;
    margin-bottom: -20px;
}

.prompt-buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
}

.prompt-button {
    height: 38px;
    width: 150px;
    min-height: unset;
    min-width: unset !important;
    margin-left: 8px !important;
}
