.zoom-controls {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    gap: 18px;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #e4e1eb;
    background: #fff;
    box-shadow: 0 -2px 8px 0 rgba(20, 9, 42, 0.1);
}

.zoom-percentage {
    font-size: 14px;
}

.percentage-box {
    width: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}
