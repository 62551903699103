.searchBar {
    border: 1px solid #e2e2e1;
    overflow: hidden;
    border-radius: 4px;
    background-color: #ffffff;
    transition: box-shadow 300ms;
    &:hover {
        box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.08);
    }
}
.searchBar .MuiFormControl-root {
    margin: 0 !important;
}
.searchInput {
    width: 100%;
    padding: 10px 12px;
}

.searchIcon {
    padding: 0 10px;
}
