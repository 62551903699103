.screen-container {
    padding-top: 56px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #f9f9f9;
}

.explorer-nav {
    background-color: #422e47;
    color: white;
    min-height: 56px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 20px;
    justify-content: space-between;
}

.explorer-nav-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 16px;
}

.explorer-nav-actions svg {
    fill: white;
}

.reset-insight {
    margin-right: 10px !important;
    min-height: unset !important;
    height: 34px !important;
    min-width: unset !important;
    width: 80px !important;
    padding: 8px !important;
    margin-left: 8px !important;
}

.explorer-content {
    position: relative;
    height: 100%;
    width: 100%;
}

.drawer-tabs-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 20px;
}

.drawer-tab {
    font-family: Inter, sans-serif !important;
    text-transform: none !important;
    color: black !important;
}

.drawer-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 16px;
    height: calc(100% - 48px);
    overflow: auto;
}

.drawer-section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.filter-button {
    height: 38px;
    width: 100%;
    min-height: unset;
    min-width: unset !important;
    margin-top: 10px !important;
}

.new-palette-button {
    height: 40px;
    width: 110px;
    margin-left: 10px !important;
    min-height: unset;
    min-width: unset !important;
    margin-top: 10px !important;
    font-size: 12px !important;
}

.add-button {
    border: 1px solid #e7e7e7;
    height: 40px;
    border-radius: 8px;
    display: grid;
    place-content: center;
    margin-top: 10px;
}

.add-button:hover {
    cursor: pointer;
}

.drawer-selected-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #e8e8e8;
    border-radius: 8px;
    height: 40px;
    margin-top: 10px;
    padding: 0 12px 0 8px;
}

.add-modal {
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 500px;
    width: 400px;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.buttons-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-transform: none !important;
    margin-top: 10px;
}

.insight-container {
    height: 100%;
    width: 100%;
    background-size: 20px 20px;
}

.insight-container-light {
    background-image: conic-gradient(
        white 0 25%,
        rgb(239, 239, 239) 0 50%,
        white 0 75%,
        rgb(239, 239, 239) 0
    );
}

.insight-container-dark {
    background-image: conic-gradient(
        black 0 25%,
        rgb(51, 51, 51) 0 50%,
        black 0 75%,
        rgb(51, 51, 51) 0
    );
}

.MuiFormControl-root {
    margin-top: 10px !important;
}

.MuiInputBase-root {
    border-radius: 5px !important;
}

.filters-container {
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    max-height: calc(100% - 48px);
    overflow: auto;
}

.MuiSelect-select {
    display: flex !important;
    align-items: center;
    gap: 8px;
}

.view-select-item {
    padding: 8px;
    align-items: center;
    gap: 8px;
}

.select-icons {
    padding: 8px;
    background-color: #fce7ee;
    border-radius: 3px;
}

.invert-axis-button {
    height: 30px;
    width: 100%;
    min-height: unset;
    min-width: unset !important;
    margin-top: 10px !important;
}
