.css-117w1su-MuiStepIcon-text {
    fill: black !important;
    font-weight: 600;
}

.object-name {
    font-family: Inter, monospace;
    font-size: 24px;
    color: black;
    font-weight: 600;
    width: 100%;
}

.fields-button {
    height: 30px;
    width: fit-content;
    font-size: 14px !important;
    min-height: unset;
    min-width: unset !important;
    margin-top: 10px !important;
}
