.canvas-box {
    width: 290px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 250px;
}

.canvas-image-container {
    width: 100%;
    height: 160px;
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #e6e4e6;
}

.canvas-image-container img {
    width: 100%;
    height: 100%;
}

.canvas-text-container {
    width: 100%;
    height: 40px;
}

.canvas-name {
    color: #2b1630;
    font-size: 14px;
    font-weight: 600 !important;
    font-family: Inter, sans-serif !important;
}

.options-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: absolute;
    top: 0;
    width: 100%;
    height: 160px;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.05);
    padding: 8px;
}

/*.canvas-box:hover {*/
/*    opacity: 0.7;*/
/*}*/

/*.canvas-box:hover .overlay {*/
/*    background-color: rgba(255, 255, 255, 0.5);*/

/*}*/
/*.canvas-box:hover .canvas-name {*/
/*    display: block;*/
/*}*/
/*.canvas-box:hover .close-button {*/
/*    display: block;*/
/*}*/

/*.canvas-box .overlay{*/
/*    display: flex;*/
/*    align-items: center;*/
/*    justify-content: center;*/
/*    height: 100%;*/
/*    width: 100%;*/
/*    background-color: rgba(255, 255, 255, 0);*/
/*    transition: 0.3s;*/
/*}*/

/*.canvas-box .close-button{*/
/*    position: absolute;*/
/*    top: 2%;*/
/*    right: 2%;*/
/*    cursor: pointer;*/
/*    transition: 0.3s;*/
/*    display: none;*/
/*}*/
