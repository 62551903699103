.filter-creator-selectors-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}

.filter-creator-selector {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.filter-creator-category-values {
    overflow: auto;
}

.filter-creator-loader-container {
    width: 100%;
    display: grid;
    place-content: center;
    height: 100%;
}

.filter-creator-footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.save-filter-button {
    height: 30px;
    font-size: 14px !important;
    min-height: unset;
    min-width: unset !important;
}

.add-like-value-button {
    height: 33px;
    font-size: 14px !important;
    min-height: unset;
    min-width: unset !important;
}
