.error-boundary-screen {
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-support-text {
    text-decoration: underline;
    color: #e50b54;
}

.error-support-text:hover {
    cursor: pointer;
}

.error-continue-button {
    margin-top: 20px !important;
    width: 200px !important;
}
