.canvases-screen-container {
    padding: 120px 64px 64px 64px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #fff;
}

.canvases-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
}

.canvases-pink {
    height: 56px;
    width: 56px;
    background-color: #fce7ee;
    border-radius: 50%;
    display: grid;
    place-content: center;
}

.header-text {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.inter {
    font-family: Inter, sans-serif !important;
}

.new-canvas {
    height: 38px;
    width: 140px;
    min-height: unset;
    min-width: unset !important;
}

.canvases-screen-container .MuiFormControl-root {
    margin-top: 0px !important;
}

.cancel-create {
    height: 38px;
    min-height: unset;
}

.canvases-container {
    margin-top: 60px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 24px;
    height: 100%;
    overflow: auto;
    width: 100%;
}
