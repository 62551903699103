.kpi-tab-subtext {
    margin-bottom: 10px !important;
    font-size: 14px !important;
    color: #979099;
}

.applied-filters {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid #d0cdd1;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
}
