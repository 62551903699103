.worksheet-screen-container {
    padding: 120px 64px 64px 64px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: #fff;
}

.worksheets-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;
    margin-bottom: 20px;
}

.worksheet-pink {
    height: 56px;
    width: 56px;
    background-color: #fce7ee;
    border-radius: 5px;
    display: grid;
    place-content: center;
}

.header-text {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.inter {
    font-family: Inter, sans-serif !important;
}

.new-worksheet {
    height: 38px;
    width: 190px;
    min-height: unset;
    min-width: unset !important;
}

.worksheet-screen-container .MuiFormControl-root {
    margin-top: 0px !important;
}

.worksheet-screen-container .ag-root-wrapper {
    background-color: transparent !important;
    border: none;
}

.worksheet-screen-container .ag-header {
    background-color: #f2f2f2 !important;
    border-bottom: none !important;
    border-radius: 5px 5px 0 0;
}

.worksheet-screen-container .ag-header-cell {
    color: #2b1630 !important;
    font-family: Inter, sans-serif !important;
    font-weight: 600;
    font-size: 14px;
}

.worksheet-screen-container .ag-cell {
    font-family: Inter, sans-serif !important;
    padding-left: 16px;
    display: flex;
    align-items: center;
}

.worksheet-screen-container .ag-row {
    height: 72px !important;
    background-color: #fff !important;
    border-bottom: 1px solid #f2f2f2;
}

.worksheet-screen-container .ag-row:hover {
    background-color: #f9f9f9 !important;
}

.worksheet-cell {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.platform-pill {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    border-radius: 5px;
    padding: 4px 8px;
    background-color: #f9f9f9;
    width: fit-content;
    font-size: 12px;
    height: 26px;
}

.cancel-create {
    height: 38px;
    min-height: unset;
}

.info-text-container {
    width: 450px;
    border-left: 3px solid #e50b54;
    background-color: #fef4f7;
    border-radius: 5px;
    padding: 12px 12px 12px 16px;
}
