.fr-box,
.fr-wrapper {
    height: 100% !important; /* Force the editor to occupy the container */
    z-index: 0;
    border-radius: 0 !important;
    border: none !important;
    background-color: transparent !important;
}

.disabled-links .fr-view a {
    pointer-events: none;
    text-decoration: none;
    cursor: default;
}

.fr-element {
    padding: 0 !important;
}

.fr-toolbar {
    width: 750px !important;
    border-radius: 0px !important;
    border: none !important;
}

.fr-second-toolbar {
    display: none;
}

.fr-newline {
    display: none !important;
}

.textEditor-title {
    position: absolute;
    left: 11px;
    top: -23px;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #6191f2;
}

.textEditor-menu {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 7px 0 rgba(20, 9, 42, 0.15);
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.edit-title-input {
    position: absolute;
    left: -3px;
    top: -28px;
    font-weight: 600;
    font-size: 12px;
    font-family: Inter, sans-serif;
    border: 2px solid #6191f2;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    padding: 4px 12px;
    color: #6191f2;
}

.edit-title-input:focus {
    outline: none;
}

.ql-container {
    border: none !important;
}

.text-editor-display {
    overflow: hidden;
    font-size: 13px;
    height: 100%;
    padding: 7px 15px;
}

.text-editor-display h1 {
    margin: 0;
}

.text-editor-display h2 {
    margin: 0;
}

.text-editor-display h3 {
    margin: 0;
}

.text-editor-display h4 {
    margin: 0;
}

.text-editor-display h5 {
    margin: 0;
}

.text-editor-display h6 {
    margin: 0;
}

.text-editor-display p {
    margin: 0;
}

.fr-action-buttons .fr-command.fr-submit {
    background: #e50b54 !important;
    color: #ffffff !important;
    border-radius: 5px !important;
    padding: 10px 16px !important;
    font-size: 16px !important;
    font-family: Inter, sans-serif !important;
    &:hover {
        background: #bf0a46 !important;
    }
}

[id^='fr-link-insert-layer-url-'] {
    border: 1.5px solid #e3e3e3 !important;
    padding: 8px !important;
    border-radius: 6px !important;
    color: #14092a !important;
}

[id^='fr-link-insert-layer-url-']::placeholder {
    color: #14092a !important;
}

[id^='fr-link-insert-layer-url-']:focus {
    border: 1.5px solid #e50b54 !important;
    outline: none !important;
}

[id^='fr-link-insert-layer-text-'] {
    border: 1.5px solid #e3e3e3 !important;
    padding: 8px !important;
    border-radius: 6px !important;
    color: #14092a !important;
}

[id^='fr-link-insert-layer-text-']::placeholder {
    color: #14092a !important;
}

[id^='fr-link-insert-layer-text-']:focus {
    border: 1.5px solid #e50b54 !important;
    outline: none !important;
}

.fr-input-line label {
    font-family: Inter, sans-serif;
}

.fr-checkbox-line {
    display: flex !important;
    gap: 8px !important;
    align-items: center !important;
    height: auto !important;
}

.fr-checkbox-line label {
    font-family: Inter, sans-serif;
    color: #14092a !important;
    font-size: 14px !important;
}

.fr-checkbox {
    padding: 0 !important;
}

.fr-checkbox span {
    width: 20px !important;
    height: 20px !important;
    border: 2px solid #c7c5c7 !important;
    border-radius: 4px !important;
    cursor: pointer;
}

.fr-popup .fr-checkbox input:checked + span {
    background-color: #e50b54 !important;
    border-color: #e50b54 !important;
}

.fr-popup .fr-checkbox input:checked + span:hover {
    background-color: #e50b54 !important;
    border-color: #e50b54 !important;
}

.fr-popup .fr-checkbox svg {
    fill: white !important;
    width: 12px !important;
    height: 12px !important;
    margin: auto;
}

.fr-checkbox input[type='checkbox']:checked + span svg path {
    fill: white !important;
    width: 12px !important;
    height: 12px !important;
    margin: auto;
}
