.tableHeader {
    word-wrap: break-word;
    font-weight: bold;
    text-align: center;
    line-height: 1;
}

.handsontable .ht_clone_top,
.handsontable .ht_clone_top .wtHider,
.handsontable .ht_clone_top .wtHolder {
    z-index: 0 !important;
}

.handsontable .htCore,
.ht_clone_left,
.handsontable .ht_clone_left .wtHider,
.handsontable .ht_clone_left .wtHolder {
    z-index: 0 !important;
}
