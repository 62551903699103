.color-picker-box {
    border-radius: 4px;
    box-sizing: border-box;
    border: 1px solid #e6e4e6;
}

.remove-border {
    border: none;
}

.transparent-box {
    border: 1px solid #ccc;
    background: linear-gradient(45deg, white 45%, red 45%, red 55%, white 55%);
}

.color-picker-container div[title='#00000000'] {
    box-sizing: border-box;
    position: relative;
    background: white;
    border: 1px solid #ccc;
}

.color-picker-container div[title='#00000000']::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(45deg, white 45%, red 45%, red 55%, white 55%);
    z-index: 1;
}
