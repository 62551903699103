.frame-title {
    position: absolute;
    left: 11px;
    top: -23px;
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #6191f2;
}

.frame-menu {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 7px 0 rgba(20, 9, 42, 0.15);
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.edit-title-input {
    position: absolute;
    left: -3px;
    top: -28px;
    font-weight: 600;
    font-size: 12px;
    font-family: Inter, sans-serif;
    border: 2px solid #6191f2;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    padding: 4px 12px;
    color: #6191f2;
}

.edit-title-input:focus {
    outline: none;
}

.frame-drawer-content {
    padding: 24px;
    width: 400px;
    height: calc(100% - 115px);
    display: flex;
    flex-direction: column;
}

.frame-drawer-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
}

.frame-drawer-body {
    flex: 1;
    overflow: auto;
    margin: 8px 0;
}

.frame-drawer-footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.frame-drawer-button {
    height: 38px;
    width: 150px;
    min-height: unset;
    min-width: unset !important;
}

.frame-draggable {
    padding: 8px 12px;
    border: 1px solid #e6e4e6;
    height: 54px;
    border-radius: 5px;
    background-color: white;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.preview-image-container {
    height: 225px;
    width: 400px;
}

.background-pill {
    border-radius: 5px;
    border: 1px solid #d0cdd1;
    padding: 0 4px;
}

.background-pill:hover {
    cursor: pointer;
}

.selected-pill {
    background-color: #e50b54;
    color: white;
    border: 1px solid #e50b54;
}
