.dataset-insights-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    height: calc(100% - 56px);
    padding: 0 24px 24px 24px;
    margin-top: 56px;
    background-color: #f2f2f2;
    max-height: calc(100% - 56px);
}
.dataset-insights-topnav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 62px;
    padding: 12px 0;
    border-bottom: 1px solid #e6e4e6;
}
.dataset-details {
    width: 25%;
}
.dataset-tabs-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px;
    background-color: #ffffff;
    border-radius: 5px;
    overflow: hidden;
    flex-grow: 1;
}
.dataset-insights-container .MuiBox-root.css-19kzrtu {
    padding: 0 16px !important;
    overflow: auto;
}
.dataset-insights-container .MuiPaper-root {
    box-shadow: none !important;
    width: 100%;
}
