.theme-item-container {
    display: flex;
    flex-direction: column;
    border: 1px solid #e6e4e6;
    border-radius: 5px;
    height: 170px;
    min-height: 170px;
    width: 100%;
    overflow: hidden;
    background-size: 20px 20px;
}

.transparent-light {
    background-image: conic-gradient(
        white 0 25%,
        rgb(239, 239, 239) 0 50%,
        white 0 75%,
        rgb(239, 239, 239) 0
    );
}

.transparent-dark {
    background-image: conic-gradient(
        black 0 25%,
        rgb(51, 51, 51) 0 50%,
        black 0 75%,
        rgb(51, 51, 51) 0
    );
}

.theme-clickable {
    cursor: pointer;
}

.preview-theme {
    border: 1px solid #f5568a;
}

.theme-item-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e4e6;
    background-color: white;
    min-height: 50px;
    padding: 8px 16px;
}

.theme-item-controls {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.apply-theme-button {
    min-height: unset !important;
    height: 30px !important;
    min-width: unset !important;
    width: 54px !important;
    font-size: 14px !important;
}

.theme-item-preview {
    height: 100%;
}
