.screen-container {
    padding-top: 56px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: white;
}

.processdiagram-nav {
    background-color: #422e47;
    color: white;
    height: 56px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    font-size: 20px;
    justify-content: space-between;
    z-index: 1000;
}

.processdiagram-nav-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
}

.processdiagram-nav-actions svg {
    fill: white;
}

.processdiagram-content {
    position: relative;
    height: 100%;
    width: 100%;
}

.label-container {
    min-height: 44px;
    cursor: pointer;
    flex: 1;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.attributes-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    width: 100%;
    min-height: 44px;
    border-radius: 8px;
    align-self: stretch;
    margin: 0px 0px;
}

.label-container {
    min-height: 44px;
    cursor: pointer;
    flex: 1;
    height: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
}

.dot {
    width: 8px;
    height: 8px;
    margin: 0px 0px 0px 4px;
    border-radius: 8px;
}

.attribute-text {
    font-family: Poppins;
    font-style: normal;
    font-weight: 400;
    font-size: 9px;
    flex: 1;
    line-height: 12px;
    letter-spacing: 0.01em;
    color: #ffffff;
    margin: 0px 4px;
}

.modal-button-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.modal-button-negative {
    padding: 10px 16px;
    height: 44px;
    left: 0px;
    top: 0px;
    margin-right: 5px;
    background: #d4ced6;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
    color: #1b1020;
    border: none;
    width: 100%;
    flex: 1;
}

.modal-button-positive {
    padding: 10px 16px;
    height: 44px;
    left: 0px;
    top: 0px;
    margin-left: 5px;
    background: #ff1662;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
    color: #fff;
    border: none;
    width: 100%;
    flex: 1;
}

.node-text {
    margin-left: 5px;
    margin-right: 5px;
    color: white;
    font-size: smaller;
}

.process-style-drawer {
    padding: 24px;
    width: 400px;
    height: calc(100% - 112px);
    display: flex;
    flex-direction: column;
    gap: 8px;
}
