.generic-content-container {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.generic-content-container img {
    pointer-events: none;
}

.shape-menu {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 0 7px 0 rgba(20, 9, 42, 0.15);
    padding: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
}
