.drilling-breadcrumbs-container {
    height: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.drill-step {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.clickable-step:hover {
    text-decoration: underline;
    cursor: pointer;
}
