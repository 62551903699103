.input-container {
    display: flex !important;
    flex-direction: column !important;
    width: 100%;
    align-items: flex-start;
}

.input {
    border-radius: 6px;
    border: 1.5px solid #e3e3e3 !important;
    color: #14092a !important;
}

.input-label {
    color: #14092a;
    font-family: Inter, sans-serif;
    font-size: 14px;
    font-weight: 600;
}

.input fieldset {
    border: none !important;
}

.purple-border-input {
    border: 1.5px solid #e50b54 !important;
}
