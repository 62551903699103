.presentation-controls-container {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    background-color: white;
    border-radius: 5px;
    transition: bottom 300ms ease;
}

.presentation-controls {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px;
    gap: 4px;
    box-shadow: 0 0 7px 0 rgba(20, 9, 42, 0.15);
}

.toggle-controls {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translate(-50%);
    width: 40px;
    display: grid;
    place-content: center;
    border-radius: 5px 5px 0 0;
    background-color: white;
}

.toggle-controls:hover {
    cursor: pointer;
}

.slide-title-button {
    width: 160px;
    height: 36px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 12px;
}

.slide-title-button:hover {
    background: #f2f2f2;
    cursor: pointer;
}

.end-button {
    min-height: unset !important;
    height: 36px !important;
    min-width: unset !important;
    width: 60px !important;
}
