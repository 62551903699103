@font-face {
    font-family: 'Source Sans Pro';
    src:
        local('Source Sans Pro'),
        url(./fonts/SourceSansPro-Regular.otf) format('opentype');
}

.fr-view {
    height: 100%;
}

:root {
    --grid-header-background-color: #e6e4e6;
    --grid-body-background-color: #f0f0f0;
    --grid-font-family: Inter, sans-serif;
    --grid-header-font-family: Inter, sans-serif;
    --grid-font-weight: 400;
    --grid-header-font-weight: 600;
    --grid-font-size: 14px;
    --grid-header-font-size: 14px;
    --grid-header-font-color: #412747;
    --grid-font-color: #412747;
    --grid-border: 1px solid #d0cdd1;
}

html,
body {
    overscroll-behavior-x: none;
}
