.iq-primary-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 6px !important;
    font-family: Inter, sans-serif !important;
    font-size: 16px !important;
    text-transform: none !important;
    width: 108px;
    line-height: 110% !important;
    color: #14092a !important;
    min-height: 50px;
}

.iq-primary-button-wide {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    border-radius: 6px !important;
    font-family: Inter, sans-serif !important;
    font-size: 16px !important;
    text-transform: none !important;
    width: 216px;
    line-height: 110% !important;
    color: #14092a !important;
    min-height: 50px;
}

.iq-primary-button.small {
    min-height: 35px;
    font-size: 14px !important;
    white-space: nowrap;
    min-width: fit-content;
    padding: 10px 16px;
}

.iq-button-purple {
    background: #e50b54 !important;
    color: #ffffff !important;
}

.iq-button-no-color {
    color: #ffffff !important;
}
.iq-button-grey {
    background: #f2f2f2 !important;
}

.iq-button-disabled {
    color: #979099 !important;
    background: #d0cdd1 !important;
}

.iq-icon-button {
    padding: 10px 10px !important;
    aspect-ratio: 1/1;
    background-color: #f2f2f2 !important;
    border-radius: 5px !important;
    width: min-content !important;
    min-height: min-content !important;
    min-width: min-content !important;
}

.iq-button:hover {
    background: #eeeeeeee !important;
}

.iq-button-purple:hover {
    background: #bf0a46 !important;
}
