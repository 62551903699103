.element-info-container {
    position: absolute;
    left: 11px;
    top: -23px;
}

.element-info-container:hover {
    cursor: text;
}

.element-info-text {
    font-weight: 600 !important;
    font-size: 12px !important;
    color: #6191f2;
}

.edit-element-title-input {
    position: absolute;
    left: -14px;
    top: -5px;
    font-weight: 600;
    font-size: 12px;
    font-family: Inter, sans-serif;
    border-top: 2px solid #6191f2;
    border-right: 2px solid #6191f2;
    border-left: 2px solid #6191f2;
    border-radius: 5px 5px 0 0;
    border-bottom: none;
    padding: 4px 12px;
    color: #6191f2;
}

.edit-element-title-input:focus {
    outline: none;
}
