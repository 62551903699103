.sub-nav {
    position: fixed;
    width: 100%;
    height: 56px;
    background-color: #422e47;
    color: white;
    min-height: 59px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    font-size: 20px;
    justify-content: space-between;
    z-index: 1;
}

.sub-nav-button {
    min-height: 41px !important;
    font-size: 16px;
    width: auto;
    color: #eeeeee !important;
}

.sub-nav-small-button {
    min-height: 0 !important;
    min-width: 0 !important;
    color: #eeeeee !important;
    width: auto;
}

.sub-nav-small-button.purple {
    background-color: #e50b54;
}

.sub-nav-small-button.purple:hover {
    background-color: #bf0a46;
}
