.drawer-xButton {
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
}
.drawer-container .MuiDrawer-paper {
    padding: 24px !important;
    width: 600px !important;
}
