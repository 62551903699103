.menu-container {
    position: fixed;
    top: 140px;
    left: 50%;
    transform: translate(-50%);
    background-color: white;
    border-radius: 10px;
    padding: 10px;
    border: 1px solid #e4e1eb;
    box-shadow: 0 -2px 8px 0 rgba(20, 9, 42, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
}
